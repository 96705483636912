import "./style.css";

const ContactUs = () => {
  return (
    <section className="contact-us-section">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-8 middle_section shadow-sm">
            {/* <h2 className="contact-title"></h2> */}
            <p className="contact-text fs-4 mb-0">
              Get your Free Audit Analysis
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
