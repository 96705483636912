import React from "react";
import "./style.css";

function index() {
  return (
    <div>
      {" "}
      <div
        class="modal fade modal-lg"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header ">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                Terms and Conditions
              </h1>
              <button
                type="button"
                class="btn-close close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>Effective: June 13, 2024</p>
              <p>
                Thank you for using our services! These terms of service
                (“Terms”) cover your use and access to our services, client
                software, and websites ("Services"). By using our Services, you
                agree to be bound by these Terms and our Privacy Policy. If you
                are using our Services for an organization, you are agreeing to
                these Terms on behalf of that organization.
              </p>

              <h6>Your Information and Your Permissions</h6>
              <p>
                When you use our Services, you provide us with things like your
                files, content, messages, contacts, and so on (your
                information). Your information is yours. These Terms do not give
                us any rights to your information except for the limited rights
                that enable us to offer the Services.
              </p>
              <p>
                Our Services also provide you with features like eSign, file
                sharing, email newsletters, appointment setting, and more. These
                and other features may require our systems to access, store, and
                scan your information. You give us permission to do those
                things, and this permission extends to our affiliates and
                trusted third parties we work with.
              </p>

              <h6>Your Responsibilities</h6>
              <p>
                You are responsible for your conduct. Your information and you
                must comply with applicable laws. Content in the Services may be
                protected by others’ intellectual property rights. Please do not
                copy, upload, download, or share content unless you have the
                right to do so. We may review your conduct and content for
                compliance with these Terms. With that said, we have no
                obligation to do so. We are not responsible for the content
                people post and share via the Services.
              </p>
              <p>
                Help us keep you informed and your information protected.
                Safeguard your password to the Services, and keep your account
                information current. Do not share your account credentials or
                give others access to your account.
              </p>
              <p>
                You may use our Services only as permitted by applicable law,
                including export control laws and regulations.
              </p>

              <h6>
                <>Our Information</>
              </h6>
              <p>
                The Services are protected by copyright, trademark, and other US
                and foreign laws. These Terms do not grant you any right, title,
                or interest in the Services, others’ content in the Services,
                StopOverPayingFees, and our trademarks, logos, and other brand
                features. We welcome feedback, but note that we may use comments
                or suggestions without any obligation to you.
              </p>

              <h6>
                <>Copyright</>
              </h6>
              <p>
                We respect the intellectual property of others and ask that you
                do too. We respond to notices of alleged copyright infringement
                if they comply with the law, and such notices should be reported
                to info@stopoverpayingfees.com. We reserve the right to delete
                or disable content alleged to be infringing and terminate
                accounts of repeat infringers.
              </p>

              <h6>
                <>Termination</>
              </h6>
              <p>
                You are free to stop using our Services at any time. We reserve
                the right to suspend or terminate your access to the Services
                with notice to you if:
              </p>
              <p>(a) you are in breach of these Terms,</p>
              <p>
                (b) you are using the Services in a manner that would cause a
                real risk of harm or loss to us or other users, or
              </p>
              <p>
                We will provide you with reasonable advance notice via the email
                address associated with your account to remedy the activity that
                prompted us to contact you and give you the opportunity to
                export your information from our Services. If after such notice
                you fail to take the steps we ask of you, we will terminate or
                suspend your access to the Services.
              </p>
              <p>We will not provide notice before termination where:</p>
              <p>(a) you are in material breach of these Terms,</p>
              <p>
                (b) doing so would cause us legal liability or compromise our
                ability to provide the Services to our other users, or
              </p>
              <p>(c) we are prohibited from doing so by law.</p>

              <h6>
                <>Discontinuation of Services</>
              </h6>
              <p>
                We may decide to discontinue the Services in response to
                unforeseen circumstances beyond StopOverPayingFees’s control or
                to comply with a legal requirement. If we do so, we will give
                you reasonable prior notice so that you can export your
                information from our systems.
              </p>

              <h6>
                <>Services “AS IS”</>
              </h6>
              <p>
                We strive to provide great Services, but there are certain
                things that we cannot guarantee. TO THE FULLEST EXTENT PERMITTED
                BY LAW, StopOverPayingFees AND ITS AFFILIATES, SUPPLIERS, AND
                DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED,
                ABOUT THE SERVICES. THE SERVICES ARE PROVIDED "AS IS." WE ALSO
                DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Some places do not
                allow the disclaimers in this paragraph, so they may not apply
                to you.
              </p>

              <h6>
                <>Limitation of Liability</>
              </h6>
              <p>
                WE DO NOT EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD
                BE ILLEGAL TO DO SO—THIS INCLUDES ANY LIABILITY FOR
                StopOverPayingFees OR ITS AFFILIATES’ FRAUD OR FRAUDULENT
                MISREPRESENTATION IN PROVIDING THE SERVICES. IN COUNTRIES WHERE
                THE FOLLOWING TYPES OF EXCLUSIONS ARE NOT ALLOWED, WE ARE
                RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A
                REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE REASONABLE
                CARE AND SKILL OR OUR BREACH OF OUR CONTRACT WITH YOU. THIS
                PARAGRAPH DOES NOT AFFECT CONSUMER RIGHTS THAT CANNOT BE WAIVED
                OR LIMITED BY ANY CONTRACT OR AGREEMENT.
              </p>
              <p>
                IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE
                ALLOWED, StopOverPayingFees, ITS AFFILIATES, SUPPLIERS, OR
                DISTRIBUTORS WILL NOT BE LIABLE FOR:
              </p>
              <p>
                ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR
                CONSEQUENTIAL DAMAGES, OR ANY LOSS OF USE, DATA, BUSINESS, OR
                PROFITS, REGARDLESS OF LEGAL THEORY. THESE EXCLUSIONS OR
                LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT
                StopOverPayingFees OR ANY OF ITS AFFILIATES HAS BEEN WARNED OF
                THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p>
                IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS, OR RE-SALE
                PURPOSE, StopOverPayingFees, ITS AFFILIATES, SUPPLIERS, OR
                DISTRIBUTORS WILL HAVE NO LIABILITY TO YOU FOR ANY LOSS OF
                PROFIT, LOSS OF BUSINESS, BUSINESS INTERRUPTION, OR LOSS OF
                BUSINESS OPPORTUNITY. StopOverPayingFees AND ITS AFFILIATES ARE
                NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF
                ANY USER OF THE SERVICES.
              </p>

              <h6>
                <>Resolving Disputes</>
              </h6>
              <p>
                Let’s Try To Sort Things Out First. We want to address your
                concerns without needing a formal legal case. Before filing a
                claim against StopOverPayingFees or our affiliates, you agree to
                try to resolve the dispute informally by contacting
                info@stopoverpayingfees.com. We will try to resolve the dispute
                informally by contacting you via email.
              </p>
              <p>
                Judicial forum for disputes. You and StopOverPayingFees agree
                that any judicial proceeding to resolve claims relating to these
                Terms or the Services will be brought in the federal or state
                courts of Texas, subject to the mandatory arbitration provisions
                below. Both you and StopOverPayingFees consent to venue and
                personal jurisdiction in such courts.
              </p>
              <p>
                We Both Agree To Arbitrate. You and StopOverPayingFees agree to
                resolve any claims relating to these Terms or the Services
                through final and binding arbitration by a single arbitrator.
                This includes disputes arising out of or relating to
                interpretation or application of this “Mandatory Arbitration
                Provisions” section, including its enforceability, revocability,
                or validity.
              </p>
              <p>
                Arbitration Procedures. The American Arbitration Association
                (AAA) will administer the arbitration under its Commercial
                Arbitration Rules and the Supplementary Procedures for Consumer
                Related Disputes. The arbitration will be held in the United
                States county where you live or work, Texas, or any other
                location we agree to.
              </p>
              <p>
                NO CLASS ACTIONS. You may only resolve disputes with us on an
                individual basis and may not bring a claim as a plaintiff or a
                class member in a class, consolidated, or representative action.
                Class arbitrations, class actions, private attorney general
                actions, and consolidation with other arbitrations are not
                allowed. If this specific paragraph is held unenforceable, then
                the entirety of this “Mandatory Arbitration Provisions” section
                will be deemed void.
              </p>

              <h6>
                <>Controlling Law</>
              </h6>
              <p>
                These Terms will be governed by California law except for its
                conflicts of laws principles. However, some countries (including
                those in the European Union) have laws that require agreements
                to be governed by the local laws of the consumer's country. This
                paragraph does not override those laws.
              </p>

              <h6>
                <>Entire Agreement</>
              </h6>
              <p>
                These Terms constitute the entire agreement between you and
                StopOverPayingFees with respect to the subject matter of these
                Terms and supersede and replace any other prior or
                contemporaneous agreements or terms and conditions applicable to
                the subject matter of these Terms. These Terms create no
                third-party beneficiary rights.
              </p>

              <h6>
                <>Revised Waiver, Severability & Assignment Terms</>
              </h6>
              <p>
                At StopOverPayingFees, failure to enforce a particular provision
                does not mean that we waive our right to enforce it later. If a
                provision is deemed unenforceable, the remaining terms of the
                agreement will continue to remain in effect, and we will
                substitute the unenforceable provision with one that reflects
                our intentions as closely as possible. Please note that you
                cannot assign any of your rights under these Terms, and any
                attempt to do so will be considered invalid. However, we reserve
                the right to assign our rights to any affiliates, subsidiaries,
                or any successor in interest of any business associated with the
                Services.
              </p>

              <h6>
                <>Modifications Terms</>
              </h6>
              <p>
                We are committed to providing the best possible services to our
                users, which may require us to revise these Terms from time to
                time. Such revisions may be made to reflect changes in the law,
                new regulatory requirements, or improvements and enhancements
                made to our Services. If any modification affects your use of
                the Services or your legal rights, we will notify you before the
                effective date of the update. We will send you an email to the
                email address associated with your account or send you an
                in-product notification. Please note that the updated terms will
                take effect no less than 30 days from when we notify you.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
