import React, { useEffect } from "react";
import "../../../src/stylesheet.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  console.log("navbar");

  return (
    <nav class="navbar navbar-expand-lg main_navbar fixed-top">
      <div class="container">
        <a class="navbar-brand" href="home.html">
          <img
            class="header-logo"
            src="https://res.cloudinary.com/dgdag4bnp/image/upload/v1718879339/Stopoverpayingfee/tmt3y5k8zj5ct8zzfn71.png"
            alt=""
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarText"
        >
          <ul className="navbar-nav nave_item">
            <li className="nav-item ss">
              <a className="nav-link" aria-current="page" href="home.html">
                Home
              </a>
            </li>
            <li className="nav-item ss">
              <a className="nav-link" href="How_it_works.html">
                How it works
              </a>
            </li>
            <li className="nav-item ss">
              <a className="nav-link" href="Resources.html">
                Resources
              </a>
            </li>
            <li
              className="nav-item ss"
              onClick={() => navigate("/get-free-audit-analysis")}
            >
              <a className="nav-link active">Get Your Free Audit Analysis</a>
            </li>
            <li className="nav-item ">
              <a className="btn btn_nav" href="/contactUs.html">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default React.memo(Navbar);
